import {Environment} from "platform/enum/Environment";

enum StorageKey {

    APMTransRefNum = "__aspmtrn",
    PreviousQueryString = "__pqs",
    BackToPlatformButton = "__btpbtn"
}

namespace StorageKey {

    export function SelectedEnvironmentKey(): string {
        return `__useEnv_${Environment.environment()}`;
    }
}

export {
    StorageKey
}
