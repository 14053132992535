import {Environment} from "platform/enum/Environment";
import {Logger} from "platform/logger/Logger";
import {BITracker, BIUser} from "platform/analytics/BITracker";
import Utils from "platform/util/Utils";
import WebUtil from "platform/util/WebUtil";

export class BITrackerWeb implements BITracker {

    private static _instance: BITracker;
    private readonly _logger: Logger = Logger.Of("BITracker");
    private _queue: {event: string, data: any}[] = [];
    private _loaded: boolean;
    private _initialized: boolean;
    private _identified: boolean;
    private _brand: string;
    private _user: BIUser;

    private constructor() {
    }

    public static instance(): BITracker {
        return this._instance || (this._instance = new this());
    }

    public init = (token: string, brand: string) => {
        if (!this._initialized) {
            this._initialized = true;
            this._brand = brand;
            this.load().then((mixpanel) => {
                this._logger.debug("Init");
                mixpanel.init(token, {
                    debug: !Environment.isProd(),
                    loaded: () => {
                        this._logger.debug("Loaded");
                        this._loaded = true;
                        if (this._user) {
                            this.identify(this._user);
                        }
                    }
                });
            });
        }
    }

    public identify = (user: BIUser) => {
        if (this._loaded && !this._identified) {
            this._identified = true;
            this.load().then((mixpanel) => {
                this._logger.debug("Identify");
                mixpanel.identify(user.UserId);
                mixpanel.register({
                    "UserId": user.UserId,
                    "Device": user.device,
                    "Theme": user.theme,
                    "Brand": this._brand,
                    "Platform": WebUtil.isMobile() ? "Web Mobile" : "Web Desktop",
                    "IsRealAccount": Utils.isNotNull(user.realAccount) ? user.realAccount ? "yes" : "no" : null
                });
                if (Utils.isArrayNotEmpty(this._queue)) {
                    this._queue.forEach(({event, data}) => {
                        this.track(event, data);
                    });
                }
                this._queue = [];
            });
        } else {
            this._user = user;
        }
    }

    public set = (user: BIUser) => {
        this.load().then((mixpanel) => {
            this._logger.debug("Set");
            mixpanel.register({
                "UserId": user.UserId,
                "IsRealAccount": Utils.isNotNull(user.realAccount) ? user.realAccount ? "yes" : "no" : null
            });
        });
    }

    public track = (event: string, data?: any) => {
        if (this._identified) {
            this.load().then((mixpanel) => {
                mixpanel.track(event, data);
            });
        } else {
            this._queue.push({event, data});
        }
    }

    private load = async () => {
        const mixpanel = await import('mixpanel-browser/src/loaders/loader-module-core');
        return mixpanel.default;
    }
}
