import moment from "moment-mini";
import Utils from "platform/util/Utils";
import {FormikObject} from "typings";
import {FieldType} from "enum/FieldType";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {PaymentMethod} from "enum/PaymentMethod";
import {APMClientDetails} from "protocol/request/DepositByAPMRequest";
import {ValidationError} from "core/util/Validation";
import {ClientDetail, PaymentProvider} from "protocol/response/GetDepositConfigurationsResponse";
import {BrandType} from "platform/enum/BrandType";
import {DepositByAPMResponse} from "protocol/request/DepositByAPMResponse";
import WebUtil from "platform/util/WebUtil";
import {FormMethod} from "platform/enum/FormMethod";
import {DeviceDetails} from "protocol/request/DeviceDetails";
import {PaymentMethodSortId} from "core/util/PaymentMethodSortId";
import Platform from "platform/Platform";

export class DepositUtil {

    public static formatCardExpiration(formik: FormikObject<any>): string {
        const expiration: string = formik.values[FieldType.CardExpiration];
        if (Utils.isNotEmpty(expiration)) {
            const parts: string[] = expiration.split("/");
            return `${parts[0] || Translations.text(TranslationKey.mm)}/${parts[1] || Translations.text(TranslationKey.yy)}`;
        }
        return Translations.text(TranslationKey.mmYY);
    }

    public static ccExpired(expiration: string): boolean {
        if (Utils.isNotNull(expiration)) {
            const parts: string[] = expiration.split("/");
            const iMonth: number = parseInt(parts[0], 10);
            const iYear: number = parseInt(parts[1], 10);
            const century = new Date().getFullYear().toString().substring(0, 2);
            const expDate: moment.Moment = moment(`${century}${iYear}` + "-" + (iMonth < 10 ? "0" : "") + iMonth, "YYYY-MM");
            const minMonth: number = new Date().getMonth() + 1;
            const minDate: moment.Moment = moment(new Date().getFullYear() + "-" + (minMonth < 10 ? "0" : "") + minMonth, "YYYY-MM");
            return expDate.isSame(minDate) ? false : !expDate.isAfter(minDate);
        }
        return false;
    }

    public static formatCvc = (cvc: string): string => {
        if (cvc) {
            let result: string = cvc;
            while (result.length < 3) {
                result += "•";
            }
            return result;
        }
        return "•••";
    }

    public static CCFlowType(paymentMethod: string | number): string {
        if (paymentMethod) {
            switch (paymentMethod) {
                case PaymentMethod.OtherCreditCard:
                    return "Other";
            }
        }
        return null;
    }

    public static CCIssuer(cardNumber: string): string {
        if (cardNumber) {
            if (/^(5019|4571)\d+$/.test(cardNumber)) {
                return "Dankort";
            } else if (/^(?:50|5[6-9]|6[0-9])\d+$/.test(cardNumber)) {
                return "Maestro";
            } else if (/^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9][0-9]|27[0-1][0-9]|2720)\d+$/.test(cardNumber)) {
                return "MasterCard";
            } else if (/^(?:5[45]|36|30[0-5]|3095|3[8-9])\d+$/.test(cardNumber)) {
                return "Diners";
            } else if (/^(4026|4001|417500|4405|4508|4844|4913|4917)\d+$/.test(cardNumber)) {
                return "VisaElectron";
            } else if (/^4[0-9]\d+$/.test(cardNumber)) {
                return "Visa";
            } else if (/^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/.test(cardNumber)) {
                return "JCB";
            } else if (/^3[47][0-9]{13}$/.test(cardNumber)) {
                return "American Express";
            }
        }
        return null;
    }

    public static BuildAPMClientDetails(Client: ClientDetail, form: {[key: string]: string}): APMClientDetails {
        return {
            IdNumber: form[FieldType.IDCard],
            BillAddress1: Client?.Address || form[FieldType.Address],
            BillCity: Client?.City || form[FieldType.City],
            BillZipCode: Client?.ZipCode || form[FieldType.ZipCode],
            BillCountryCode: Client?.CountryCode || form[FieldType.CountryCode],
        };
    }

    public static BuildAPMTargetUrl(token: string, TransRefNum: string, QueryString: string, response: DepositByAPMResponse): string {
        const {GeneratedUrl, RequestForm} = response;
        let UseUrl: string = GeneratedUrl;
        let UseFormMethod: FormMethod;
        let UseFieldData: {Key: string, Value: string}[];
        if (RequestForm) {
            const {SubmitFormUrl, FormMethod, FieldData} = RequestForm;
            if (FormMethod && FieldData) {
                UseUrl = SubmitFormUrl;
                UseFormMethod = FormMethod;
                UseFieldData = FieldData;
            }
        }
        let TargetUrl = `https://${window.location.hostname}/SubmitForm.html?Token=${token}&TransRefNum=${TransRefNum}`
            + `&QueryString=${encodeURIComponent(QueryString)}&SubmitFormUrl=${encodeURIComponent(UseUrl)}`;
        if (Utils.isArrayNotEmpty(UseFieldData)) {
            const parameters: string[] = [];
            UseFieldData.forEach((field: {Key: string, Value: string}) => {
                parameters.push(`${field.Key}=${field.Value}`);
            });
            TargetUrl = WebUtil.addGetParams(TargetUrl, {
                FormMethod: UseFormMethod.toLowerCase(),
                FieldData: encodeURIComponent(parameters.join("(sep)"))
            });
        }
        return TargetUrl;
    }

    public static ActivityFormatValidationComment(errors: ValidationError[]): string {
        const fields: string[] = [];
        if (Utils.isArrayNotEmpty(errors)) {
            errors.forEach((error: ValidationError) => {
                fields.push(
                    Translations.TextEn(error.key, error.params)
                );
            });
        }
        return `Invalid Deposit fields: ${fields.join(", ")}`;
    }

    public static ApmId = (provider: PaymentProvider): string | number => {
        return Utils.isNotEmpty(provider?.ExternalPaymentProviderId) ? provider?.ExternalPaymentProviderId : provider?.EWalletId;
    }

    public static isApplePay = (provider: PaymentProvider): boolean => {
        return  provider?.EWalletId === 616;
    }

    public static isGooglePay = (provider: PaymentProvider): boolean => {
        return  provider?.EWalletId === 631;
    }

    public static isPayRetailer = (provider: PaymentProvider): boolean => {
        return  provider?.EWalletId === 618;
    }

    public static isBrite = (provider: PaymentProvider): boolean => {
        return  provider?.EWalletId === 623;
    }

    public static SwedenPaymentPriority = (method: PaymentMethodSortId): number => {
        if (method.Id === PaymentMethod.WireTransfer) {
            return 3;
        } else if (method.Id === 623) {
            return 2;
        } else if (method.Id === PaymentMethod.CreditCard || method.Id === PaymentMethod.OtherCreditCard) {
            return 1;
        }
        return 0;
    }

    public static ThaiPaymentPriority = (method: PaymentMethodSortId): number => {
        if (Platform.config().brandId === BrandType.id(BrandType.Zenstox)) {
            if (method.Id === PaymentMethod.CreditCard || method.Id === PaymentMethod.OtherCreditCard) {
                return 7;
            } else if (method.EWalletAccountId === 721) {
                return 6;
            } else if (method.EWalletAccountId === 722) {
                return 5;
            } else if (method.EWalletAccountId === 914) {
                return 4;
            } else if (method.EWalletAccountId === 713) {
                return 3;
            } else if (method.EWalletAccountId === 802) {
                return 2;
            } else if (method.EWalletAccountId === 915) {
                return 1;
            }
        }
        return 0;
    }

    public static PolandPaymentPriority = (method: PaymentMethodSortId): number => {
        if (Platform.config().brandId === BrandType.id(BrandType.Fortissio)) {
            if (method.EWalletAccountId === 523) {
                return 12;
            } else if (method.EWalletAccountId === 864) {
                return 11;
            } else if (method.EWalletAccountId === 908) {
                return 10;
            } else if (method.EWalletAccountId === 589) {
                return 9;
            } else if (method.EWalletAccountId === 884) {
                return 8;
            } else if (method.Id === PaymentMethod.CreditCard || method.Id === PaymentMethod.OtherCreditCard) {
                return 7;
            } else if (method.EWalletAccountId === 615) {
                return 6;
            } else if (method.EWalletAccountId === 703) {
                return 5;
            } else if (method.EWalletAccountId === 892) {
                return 4;
            } else if (method.EWalletAccountId === 560) {
                return 3;
            } else if (method.EWalletAccountId === 503) {
                return 2;
            } else if (method.Id === PaymentMethod.WireTransfer) {
                return 1;
            }
        } else {
            if (method.EWalletAccountId === 523 || method.EWalletAccountId === 879 || method.EWalletAccountId === 901) {
                return 19;
            } else if (method.Id === PaymentMethod.CreditCard || method.Id === PaymentMethod.OtherCreditCard) {
                return 18;
            } else if (method.Id === PaymentMethod.WireTransfer) {
                return 17;
            } else if (method.EWalletAccountId === 830) {
                return 16;
            } else if (method.EWalletAccountId === 892 || method.EWalletAccountId === 779) {
                return 15;
            } else if (method.EWalletAccountId === 870 || method.EWalletAccountId === 858 || method.EWalletAccountId === 876) {
                return 14;
            } else if (method.EWalletAccountId === 589 || method.EWalletAccountId === 646 || method.EWalletAccountId === 811) {
                return 13;
            } else if (method.EWalletAccountId === 891) {
                return 12;
            } else if (method.EWalletAccountId === 895 || method.EWalletAccountId === 896) {
                return 11;
            } else if (method.EWalletAccountId === 903) {
                return 10;
            } else if (method.EWalletAccountId === 615 || method.EWalletAccountId === 679 || method.EWalletAccountId === 801) {
                return 9;
            } else if (method.EWalletAccountId === 710 || method.EWalletAccountId === 800) {
                return 8;
            } else if (method.EWalletAccountId === 883 || method.EWalletAccountId === 882) {
                return 7;
            } else if (method.EWalletAccountId === 881 || method.EWalletAccountId === 880) {
                return 6;
            } else if (method.EWalletAccountId === 503) {
                return 5;
            } else if (method.EWalletAccountId === 478 || method.EWalletAccountId === 643) {
                return 4;
            } else if (method.EWalletAccountId === 645) {
                return 3;
            } else if (method.EWalletAccountId === 560 || method.EWalletAccountId === 647 || method.EWalletAccountId === 861) {
                return 2;
            } else if (method.EWalletAccountId === 469) {
                return 1;
            }
        }
        return 0;
    }

    public static GetDeviceData = (): DeviceDetails => {
        return {
            UserAgent: navigator?.userAgent,
            AcceptHeader: null,
            Language: navigator?.language,
            IpAddress: null,
            TimezoneOffset: new Date().getTimezoneOffset(),
            ColorDepth: window?.screen?.colorDepth?.toString(),
            PixelDepth: window?.screen?.pixelDepth?.toString(),
            PixelRatio: window?.devicePixelRatio?.toString(),
            ScreenHeight: window?.screen?.height,
            ScreenWidth: window?.screen?.width,
            ViewportHeight: window?.innerHeight,
            ViewportWidth: window?.innerWidth,
            JavaEnabled: false,
            JavascriptEnabled: true
        };
    }
}
