import {ActionCreatorDefinition, createAction} from "redux-ts";
import {ModalType} from "enum/ModalType";
import {Tooltip} from "core/redux/app/AppReduxState";
import {BooleanPayload, StringPayload} from "core/redux/StoreActions";
import {ActivityType} from "enum/ActivityType";
import {UrlType} from "platform/enum/UrlType";
import {TSMap} from "typescript-map";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {WebViewPlatformType} from "enum/WebViewPlatformType";
import {EnvironmentConfiguration} from "protocol/response/LoginConfigurationResponse";

export const SetEnvironmentConfigurationsType: string = "SetEnvironmentConfigurations";
export const SetWebViewPlatformType: string = "SetWebViewPlatform";
export const SetCountriesType: string = "SetCountries";
export const SetModalType: string = "SetModal";
export const CloseMeType: string = "CloseMe";
export const SetTooltipType: string = "SetTooltip";
export const SetCallMeType: string = "SetCallMe";
export const SetKycContextType: string = "SetKycContext";
export const SetKycThirdPartyRedirectType: string = "SetKycThirdPartyRedirect";
export const SetKycUrlType: string = "SetKycUrl";
export const SetCanManageLimitsType: string = "SetCanManageLimits";
export const SetBackNavigationType: string = "SetBackNavigation";
export const SetHideBackToPlatformButtonType: string = "SetHideBackToPlatformButton";
export const DoRegisterActivityType: string = "DoRegisterActivity";
export const DoGetURLType: string = "DoGetURL";
export const SetUIErrorType: string = "SetUIError";

export interface SetEnvironmentConfigurationsPayload {

    environmentConfigurations: EnvironmentConfiguration[];
}

export interface SetWebViewPlatformPayload {

    webViewPlatform: WebViewPlatformType;
}

export interface SetCountriesPayload {

    countries: TSMap<string, CountryInfo>;
}

export interface SetModalPayload {

    modalType: ModalType;
    visible: boolean;
}

export interface SetTooltipPayload {

    tooltip: Tooltip;
}

export interface DoRegisterActivityPayload {

    activity: ActivityType;
    comments?: string;
}

export interface DoGetURLPayload {

    urlType: UrlType;
}

export const SetEnvironmentConfigurations: ActionCreatorDefinition<SetEnvironmentConfigurationsPayload, any> = createAction<SetEnvironmentConfigurationsPayload>(SetEnvironmentConfigurationsType);
export const SetWebViewPlatform: ActionCreatorDefinition<SetWebViewPlatformPayload, any> = createAction<SetWebViewPlatformPayload>(SetWebViewPlatformType);
export const SetCountries: ActionCreatorDefinition<SetCountriesPayload, any> = createAction<SetCountriesPayload>(SetCountriesType);
export const SetModal: ActionCreatorDefinition<SetModalPayload, any> = createAction<SetModalPayload>(SetModalType);
export const CloseMe: ActionCreatorDefinition<any, any> = createAction<any>(CloseMeType);
export const SetTooltip: ActionCreatorDefinition<SetTooltipPayload, any> = createAction<SetTooltipPayload>(SetTooltipType);
export const SetCallMe: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetCallMeType);
export const SetKycContext: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetKycContextType);
export const SetKycThirdPartyRedirect: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetKycThirdPartyRedirectType);
export const SetKycUrl: ActionCreatorDefinition<StringPayload, any> = createAction<StringPayload>(SetKycUrlType);
export const SetCanManageLimits: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetCanManageLimitsType);
export const SetBackNavigation: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetBackNavigationType);
export const SetHideBackToPlatformButton: ActionCreatorDefinition<any, any> = createAction<any>(SetHideBackToPlatformButtonType);
export const DoRegisterActivity: ActionCreatorDefinition<DoRegisterActivityPayload, any> = createAction<DoRegisterActivityPayload>(DoRegisterActivityType);
export const DoGetURL: ActionCreatorDefinition<DoGetURLPayload, any> = createAction<DoGetURLPayload>(DoGetURLType);
export const SetUIError: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetUIErrorType);
