import {Xhr} from "platform/network/xhr/Xhr";
import {ServerType} from "platform/enum/ServerType";
import {HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {BadRequestResponse} from "protocol/response/BadRequestResponse";
import Platform from "platform/Platform";
import {UrlType} from "platform/enum/UrlType";
import {Logger} from "platform/logger/Logger";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "enum/TranslationKey";
import {TokenManager} from "core/util/TokenManager";
import WebUtil from "platform/util/WebUtil";
import {RNBridge} from "core/integration/RNBridge";
import {IntegrationReload} from "platform/integration/message/IntegrationReload";

export class XhrManager {

    private static logger: Logger = Logger.Of("XhrManager");

    private constructor() {}

    public static sendToLogin = (request: any, path: string): Promise<any> => {
        return Xhr.sendTo(ServerType.Login, request, "loginService.svc/json/" + path);
    }

    public static sendToLoginSecure = (request: any, path: string): Promise<any> => {
        return this.sendAuthenticated(ServerType.Login, request, "loginsecureService.svc/json/" + path);
    }

    public static sendToBilling(request: any, path: string, plain?: boolean): Promise<any> {
        return this.sendAuthenticated(ServerType.Billing, request, "BillingServer/MobileBillingService2.svc/json/" + path, plain);
    }

    public static sendToCompliance(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Compliance, request, "ComplianceWebsite/ComplianceFormService.svc/json/" + path);
    }

    public static sendToConfig(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Compliance, request, "ComplianceWebSite/ComplianceConfigurationsService.svc/json/" + path);
    }

    private static async sendAuthenticated(server: ServerType, request: any, path: string, plain: boolean = false): Promise<any> {
        const token: string = await TokenManager.token();
        const answer: [HttpReject, any] = await Utils.to(Xhr.sendTo(server, request, path, token, plain, null, false, 120000));
        if (answer[0]) {
            if (Utils.isNotNull(answer[0].response)) {
                try {
                    const response: BadRequestResponse = JSON.parse(answer[0].response);
                    if (response.IsSecurityFault) {
                        XhrManager.logger.info("Handle security exception.");
                        TokenManager.clear();
                        const wk: any = (window as any).webkit;
                        const mjsi: any = (window as any).MobileJsInterface;
                        if (Utils.isNotNull(wk) && Utils.isNotNull(wk.messageHandlers) && Utils.isNotNull(wk.messageHandlers.jsHandle) && Utils.isNotNull(wk.messageHandlers.jsHandle.postMessage)) {
                            wk.messageHandlers.jsHandle.postMessage("openDeposit");
                        } else if (Utils.isNotNull(mjsi)) {
                            mjsi.openDeposit();
                        } else if (WebUtil.inRNWebView()) {
                            RNBridge.send(JSON.stringify(new IntegrationReload()));
                        } else {
                            const loginUrl: string = Platform.reduxState().core.urls.get(UrlType.NewLogin);
                            if (Utils.isNotEmpty(loginUrl)) {
                                XhrManager.logger.info("Navigate to login: " + loginUrl);
                                Platform.environment().redirect(loginUrl);
                            } else {
                                XhrManager.logger.info("Login url not configured!");
                            }
                        }
                    }
                } catch (e) {
                }
            }
            return Promise.reject(answer[0]);
        } else {
            return Promise.resolve(answer[1]);
        }
    }

    public static notifyRejectReason(answer: [HttpReject, any], callback?: (errorCode: number) => {
        remoteId: number;
        extra: JSX.Element;
    }, action?: () => void, hideCloseBtn?: boolean): void {
        if (answer[0]) {
            if (Utils.isNotNull(answer[0].response)) {
                try {
                    const response: BadRequestResponse = JSON.parse(answer[0].response);
                    if (!response.IsSecurityFault) {
                        const {remoteId, extra} = callback ? callback(response.ErrorCode) : {remoteId: null, extra: null};
                        Platform.dispatch(ShowPopup({
                            popup: {
                                remoteId,
                                icon: {type: PopupIconType.ERROR},
                                message: {customValue: response.LocalizeMessage || response.Message, trKey: TranslationKey.serverErrorGeneral},
                                body: {extra},
                                showClose: !hideCloseBtn,
                                actions: [{type: PopupActionType.OK, action}]
                            }
                        }));
                    }
                } catch (e) {
                }
            }
        }
    }
}
